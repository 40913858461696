import { createContext, useEffect, useState } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "../constants";
import { useCallback } from "react";

interface IEthersContext {
  account?: string;
  provider?: ethers.providers.Web3Provider;
  connectToWallet?(): void;
}

export const EthersContext = createContext<IEthersContext>({});

export function useEthersContext() {
  const [web3Modal, setWeb3Modal] = useState<Web3Modal | undefined>(undefined);
  const [provider, setProvider] = useState<
    ethers.providers.Web3Provider | undefined
  >(undefined);
  const [account, setAccount] = useState<string>("");

  /**
   * This will prompt a connection to the users wallet(s).
   *
   * This needs to be in useCallback because it's a depedency of
   * a hook, and without useCallback would rerender infinitely.
   */
  const connectToWallet = useCallback(async () => {
    if (account) return;
    const _provider = await web3Modal?.connect();
    if (_provider) {
      _provider.on("accountsChanged", (accounts: string[]) => {
        setAccount(accounts[0]);
      });

      setProvider(new ethers.providers.Web3Provider(_provider));
    }
  }, [account, web3Modal]);

  useEffect(() => {
    if (provider) {
      const signer = provider.getSigner();

      const getAddress = async () => {
        setAccount(await signer.getAddress());
      };

      getAddress();
    }
  }, [provider]);

  useEffect(() => {
    const web3Modal = new Web3Modal({
      cacheProvider: true,
      providerOptions,
    });

    setWeb3Modal(web3Modal);
  }, []);

  useEffect(() => {
    if (web3Modal) {
      const autoReconnect = async () => {
        if (web3Modal.cachedProvider) {
          await connectToWallet();
        }
      };

      autoReconnect();
    }
  }, [web3Modal, connectToWallet]);

  return {
    connectToWallet,
    provider,
    account,
  };
}
