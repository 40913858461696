import { Button, chakra } from "@chakra-ui/react"
import { useEthers } from "../hooks"

type ConnectToWalletButtonProps = {
    prompt?: string;
    className?: string;
}

const _ConnectToWalletButton = (props: ConnectToWalletButtonProps) => {
    
    const { account, connectToWallet } = useEthers()
    
    return (
        <Button
            onClick={connectToWallet}
            className={props.className}
        >
            {account
                ? `${account.substring(0, 4)}...${account.substring(
                    account.length - 4,
                    account.length
                )}`
                : props.prompt}
        </Button>
    )
}

export const ConnectToWalletButton = chakra(_ConnectToWalletButton);