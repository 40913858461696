import React from "react";

import {
  chakra,
  Box,
  Flex,
  useColorModeValue,
  VisuallyHidden,
  HStack,
  Button,
  useDisclosure,
  VStack,
  IconButton,
  Text,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
} from "@chakra-ui/react";

import { AiOutlineMenu, AiOutlineInbox, AiFillBook } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ConnectToWalletButton } from "./ConnectToWalletButton";
import { DarkModeToggleButton } from "./DarkModeToggleButton";
import { useEthers } from "../hooks";

export const Navbar = () => {
  const { account } = useEthers();
  const bg = useColorModeValue("white", "gray.800");
  const mobileNav = useDisclosure();

  return (
    <React.Fragment>
      <chakra.header
        bg={bg}
        w="full"
        px={{ base: 2, sm: 4 }}
        py={4}
        shadow="md"
        backgroundColor={useColorModeValue("canlightblue", "candarkblue")}
      >
        <Flex alignItems="center" justifyContent="space-between" mx="auto">
          <HStack display="flex" spacing={3} alignItems="center" zIndex="999">
            <Box display={{ base: "inline-flex", md: "none" }}>
              <IconButton
                display={{ base: "flex", md: "none" }}
                aria-label="Open menu"
                fontSize="20px"
                color={useColorModeValue("gray.800", "inherit")}
                variant="ghost"
                icon={<AiOutlineMenu />}
                onClick={mobileNav.onOpen}
              />
              <Drawer
                isOpen={mobileNav.isOpen}
                placement="left"
                onClose={mobileNav.onClose}
              >
                <DrawerOverlay backgroundColor="sand" />
                <DrawerContent>
                  <DrawerCloseButton
                    color={useColorModeValue("gray.800", "inherit")}
                  />
                  <DrawerHeader
                    color={useColorModeValue("gray.800", "inherit")}
                  >
                    OneCan NFT
                  </DrawerHeader>
                  <DrawerBody>
                    <VStack>
                      <Link to="/">
                        <Image src="/logo.png" maxW="50px" />
                        <Text
                          display={{ base: "block", lg: "inline" }}
                          w="full"
                          bgClip="text"
                          bgGradient="linear(to-r, green.400,purple.500)"
                          fontWeight="extrabold"
                        >
                          Home
                        </Text>
                      </Link>

                      <Button
                        w="200px"
                        _hover={{
                          transform: "scale(1.2)",
                        }}
                      >
                        🪙 🛑 Minted Out 🛑 🌕
                      </Button>

                      <Link to={`/account/${account}`}>
                        <Button
                          w="200px"
                          _hover={{
                            transform: "scale(1.2)",
                          }}
                          leftIcon={<AiOutlineInbox />}
                        >
                          My Collection
                        </Button>
                      </Link>
                      <Link to="/scrolls">
                        <Button
                          w="200px"
                          _hover={{
                            transform: "scale(1.2)",
                          }}
                        >
                          📜 Scrolls!
                        </Button>
                      </Link>
                      <Link to="/search">
                        <Button
                          _hover={{
                            transform: "scale(1.1)",
                          }}
                        >
                          Search Recent 🔎
                        </Button>
                      </Link>
                      <Link to="/attributes">
                        <Button
                          w="200px"
                          _hover={{
                            transform: "scale(1.1)",
                          }}
                          leftIcon={<AiFillBook />}
                        >
                          Attributes
                        </Button>
                      </Link>
                      <DarkModeToggleButton />
                    </VStack>
                  </DrawerBody>

                  <DrawerFooter
                    color={useColorModeValue("gray.800", "inherit")}
                  >
                    Copyright OneCansNFT LTD.
                  </DrawerFooter>
                </DrawerContent>
              </Drawer>
            </Box>
            <chakra.a
              href="/"
              title="Home Page"
              display="flex"
              alignItems="center"
            >
              <VisuallyHidden>Spacer</VisuallyHidden>
            </chakra.a>

            <HStack spacing={3} display={{ base: "none", md: "inline-flex" }}>
              <Box
                _hover={{
                  opacity: 0.5,
                }}
                mr="4"
              >
                <Link to="/">
                  <Image src="/logo.png" maxW="60px" />
                  <Text
                    display={{ base: "block", lg: "inline" }}
                    w="full"
                    bgClip="text"
                    color="sand"
                    fontWeight="extrabold"
                  >
                    OneCan
                  </Text>
                </Link>
              </Box>

              <Button
                w="200px"
                _hover={{
                  transform: "scale(1.2)",
                }}
                disabled
              >
                Minted Out ✨
              </Button>
              <Link to={`/account/${account}`}>
                <Button
                  _hover={{
                    transform: "scale(1.1)",
                  }}
                  leftIcon={<AiOutlineInbox />}
                  bgColor="sand"
                >
                  My Collection
                </Button>
              </Link>
              <Link to="/scrolls">
                <Button
                  _hover={{
                    transform: "scale(1.1)",
                  }}
                >
                  📜 Scrolls!
                </Button>
              </Link>

              <Link to="/search">
                <Button
                  _hover={{
                    transform: "scale(1.1)",
                  }}
                >
                  Search Recent 🔎
                </Button>
              </Link>

              <Link to="/attributes">
                <Button
                  _hover={{
                    transform: "scale(1.1)",
                  }}
                  leftIcon={<AiFillBook />}
                >
                  Attributes
                </Button>
              </Link>
            </HStack>
          </HStack>
          <HStack
            spacing={3}
            display={mobileNav.isOpen ? "none" : "flex"}
            alignItems="center"
          >
            <DarkModeToggleButton />
            <ConnectToWalletButton prompt=" + Connect Wallet" />
          </HStack>
        </Flex>
      </chakra.header>
    </React.Fragment>
  );
};
