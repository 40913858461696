import { Box } from "@chakra-ui/layout";
import { Image, Skeleton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { TraitInfo } from ".";

type OneCanCardProps = {
  id: number;
};

export const OneCanCard = (props: OneCanCardProps) => {
  const [loadingCard, setLoadingCard] = useState(true);
  const [fetchedData, setFetchedData] = useState(null);
  const [imageLink, setImageLink] = useState("");

  const history = useHistory();
  const handleOnClick = () => history.push(`/onecan/${props.id}`);

  useEffect(() => {
    fetch(
      `https://onecan.mypinata.cloud/ipfs/QmcdTGLJzKoLG7mWz3krLZZTd9LnNJKfu2ERLYqhtv5sPd/${props.id}`
    ).then((resp) => {
      if (resp.ok) {
        resp.json().then((content) => {
          setFetchedData(content);
          // https://gateway.pinata.cloud/ipfs/QmWQc9AcSus5XUqNgagBFNFwQpPmXNgcgz1Sx1aqfHBBQS/123.png
          setImageLink(
            `https://onecan.mypinata.cloud/ipfs/QmWQc9AcSus5XUqNgagBFNFwQpPmXNgcgz1Sx1aqfHBBQS/${props.id}.png`
          );
        });
      } else {
        console.log("Could not fetch data");
      }
    });
  }, [props.id]);

  return (
    <Box
      m="4"
      p="1"
      maxW="sm"
      w="fit-content"
      rounded="2xl"
      boxShadow="2xl"
      sx={{
        transition: "100ms",
      }}
      _hover={{
        transition: "transform 100ms ease-in-out",
        transform: "scale(1.05)",
      }}
      cursor="pointer"
      onClick={handleOnClick}
    >
      <Skeleton rounded="lg" isLoaded={!loadingCard}>
        <Image
          alt="toucan"
          rounded="2xl"
          minW="150px"
          minH="30px"
          src={imageLink}
          onLoad={() => {
            setLoadingCard(false);
          }}
        />
        {fetchedData && <TraitInfo data={fetchedData} />}
      </Skeleton>
    </Box>
  );
};
