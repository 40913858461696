import { Box, Heading } from "@chakra-ui/layout";
import { Contract } from "ethers";
import { useEffect, useState } from "react";
import { useEthers } from "../hooks";
import { ImagesNotLoadingInfo, OneCanCard } from "../components";
import { useToast } from "@chakra-ui/toast";

type RecentlyMintedPageProps = {
  contract?: Contract;
};

export const RecentlyMintedPage = (props: RecentlyMintedPageProps) => {
  const toast = useToast();
  const { account, provider } = useEthers();
  const [ownedNFTs, setOwnedNFTs] = useState<Number[]>([]);
  const [_supply, setSupply] = useState(1);

  useEffect(() => {
    if (provider) {
      provider.getNetwork().then((value) => {
        const chainId = value.chainId;
        if (chainId !== 1666600000) {
          toast({
            title: "Sorry boss, quick network concern ",
            description:
              "Make sure you're on the HarmonyONE mainnet network on metamask pwease 🥺. Refresh when you are 😘",
            status: "error",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    }
  }, [provider, toast]);

  useEffect(() => {
    const getCollection = async () => {
      // STEP 1: Get the current owner's balance
      if (props.contract) {
        let supply = await props.contract.totalSupply();
        // STEP 2: Loop through and grab all the NFTs this user owns
        setSupply(supply);
        let lim = supply < 12 ? supply : 27;
        for (let i = 0; i < lim; i++) {
          const ans = await props.contract.tokenByIndex(supply - 1 - i);
          setOwnedNFTs((oldArray) => [...oldArray, ans]);
        }
      }
    };

    if (account && provider && props.contract) {
      getCollection();
    }
  }, [account, props.contract, provider]);

  return (
    <Box>
      {account ? (
        <>
          <Box mt="7" textAlign="center" fontSize="2xl" fontWeight="bold">
            Recently Minted OneCans! ✨
          </Box>
          <Box mt="7" textAlign="center" fontSize="lg" fontWeight="bold">
            {account && <Box> {_supply.toString()} / 10,000 minted! </Box>}
          </Box>
          <Box mx="auto" w="100%" textAlign="center">
            <ImagesNotLoadingInfo />
          </Box>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {!account ? (
              <Box> Please connect wallet (Harmony Mainnet) </Box>
            ) : ownedNFTs.length === 0 ? (
              <Box> Loading... </Box>
            ) : (
              ownedNFTs.map((boxId) => {
                return (
                  <OneCanCard key={boxId.valueOf()} id={boxId.valueOf()} />
                );
              })
            )}
          </Box>{" "}
        </>
      ) : (
        <Heading m="5">Please connect an account</Heading>
      )}
    </Box>
  );
};
