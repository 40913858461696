import { Box, Flex, Heading } from "@chakra-ui/layout";
import { Contract, ethers } from "ethers";
import { useEffect, useState } from "react";
import { useEthers } from "../hooks";
import { ImagesNotLoadingInfo, OneCanCard, StatsAccount } from "../components";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/toast";

type AccountPageProps = {
  contract?: Contract;
};

export const AccountPage = (props: AccountPageProps) => {
  const toast = useToast();
  const { accountAddress }: any = useParams();
  const { account, provider } = useEthers();
  const [ownedNFTs, setOwnedNFTs] = useState<Number[]>([]);

  useEffect(() => {
    const getCollection = async () => {
      // STEP 1: Get the current owner's balance
      if (ethers.utils.isAddress(accountAddress)) {
        if (props.contract) {
          setOwnedNFTs([]);
          let balance = await props.contract.balanceOf(accountAddress);
          // STEP 2: Loop through and grab all the NFTs this user owns
          for (let i = 0; i < balance; i++) {
            const ans = await props.contract.tokenOfOwnerByIndex(
              accountAddress,
              balance - i - 1
            );
            setOwnedNFTs((oldArray) => [...oldArray, ans]);
          }
        }
      }
    };

    if (account && provider && props.contract) {
      getCollection();
    }
  }, [account, props.contract, provider, accountAddress]);

  useEffect(() => {
    if (provider) {
      provider.getNetwork().then((value) => {
        const chainId = value.chainId;
        if (chainId !== 1666600000) {
          toast({
            title: "Sorry boss, quick network concern ",
            description:
              "Make sure you're on the HarmonyONE mainnet network on metamask pwease 🥺. Refresh when you are 😘",
            status: "error",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    }
  }, [provider, toast]);

  return (
    <Box>
      {account && ethers.utils.isAddress(accountAddress) ? (
        <>
          <Box mt="7" textAlign="center" fontSize="2xl" fontWeight="bold">
            {accountAddress === account
              ? "Your incredible collection"
              : `${accountAddress}’s lovely collection`}
          </Box>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <StatsAccount owned={ownedNFTs.length} />
            <Box mx="auto" w="100%" textAlign="center">
              <ImagesNotLoadingInfo />
            </Box>
            {ownedNFTs.length === 0 ? (
              <Box> </Box>
            ) : (
              <Box>
                <Flex wrap="wrap" justifyContent="center">
                  {ownedNFTs.map((boxId) => {
                    return <OneCanCard id={boxId.valueOf()} />;
                  })}
                </Flex>
              </Box>
            )}
          </Box>{" "}
        </>
      ) : (
        <Heading m="5">Please connect an account </Heading>
      )}
    </Box>
  );
};
