import React, { useEffect, useState } from "react";
import harmony from "./images/harmonyletoucan.png";
import discord from "./images/discordgray.png";
import marketplace from "./images/marketplacegray.png";
import twitter from "./images/twitterGray.png";
import gif from "./images/toucangif.gif";

import {
  chakra,
  Box,
  useColorModeValue,
  Flex,
  Badge,
  SimpleGrid,
  Text,
  Button,
  Image,
  Skeleton,
} from "@chakra-ui/react";
import party from "party-js";
import { useRef } from "react";

export const AltHero = () => {
  const plusRef = useRef<HTMLButtonElement>(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (plusRef !== null) {
      plusRef?.current?.addEventListener("click", function (e) {
        party.sparkles(this);
      });
    }
  }, []);

  return (
    <SimpleGrid
      columns={{ base: 1, md: 2 }}
      spacing={0}
      backgroundColor={useColorModeValue("sand", "extracandarkblue")}
      _after={{
        bg: "brand.500",
        opacity: 0.25,
        pos: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: -1,
        content: '" "',
      }}
    >
      <Flex
        direction="column"
        alignItems="start"
        justifyContent="center"
        px={{ base: 4, lg: 20 }}
        py={{ base: 10, lg: 24 }}
      >
        <Badge px={3} py={1} mb={3} rounded="full">
          ✨ minted out! ✨
        </Badge>
        <chakra.h1
          mb={6}
          fontSize={{ base: "4xl", md: "6xl" }}
          fontWeight="bold"
          lineHeight="none"
          letterSpacing={{ base: "normal", md: "tight" }}
          color={useColorModeValue("gray.900", "gray.100")}
        >
          <Text
            display={{ base: "block", lg: "inline" }}
            w="full"
            bgClip="text"
            color={useColorModeValue("candarkgreen", "sand")}
            fontWeight="extrabold"
          >
            An Island adventure awaits...
          </Text>{" "}
        </chakra.h1>
        <chakra.h6
          mb={6}
          fontSize={{ base: "xl", md: "xl", lg: "xl" }}
          fontWeight="normal"
          color={useColorModeValue("candarkblue", "white")}
          lineHeight="shorter"
        >
          Grab your OneCan NFT and hop onto the Peninsula of VolCan: a
          multiplayer social metaverse game on the Harmony One blockchain.
        </chakra.h6>

        <Flex flexWrap="wrap" flexDirection="column" w="100%">
          <Box display="inline-flex" w={{ base: "100%", md: "300px" }} mt="2">
            <chakra.a
              color="white"
              w="full"
              p="2"
              href="https://beta.onecan.one/"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              marginx="auto"
              marginy="10px"
              border="solid transparent"
              fontWeight="bold"
              rounded="md"
              bg="canlightblue"
              _hover={{
                opacity: 0.5,
              }}
            >
              Play now! (WL only 😏)
            </chakra.a>
          </Box>
          <Button
            fontWeight="bold"
            bgColor="canlightblue"
            p="4"
            color="white"
            marginx="auto"
            ref={plusRef}
            w={{ base: "100%", md: "300px" }}
            mt="20px"
          >
            10,000 / 10,000 Minted! Woohoo!
          </Button>
          <Flex
            w={{ base: "100%", md: "300px" }}
            justifyContent="space-between"
          >
            <Box display="inline-flex" rounded="md" w="100px" mt="2">
              <chakra.a
                color="black"
                w="full"
                p="2"
                href="https://discord.gg/gtpM2rRAXP"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                marginx="auto"
                border="solid transparent"
                fontWeight="bold"
                rounded="md"
                _hover={{
                  opacity: 0.5,
                }}
              >
                <Image
                  mx="2"
                  style={{
                    filter:
                      "invert(0%) sepia(100%) saturate(3%) hue-rotate(171deg) brightness(94%) contrast(101%)",
                  }}
                  src={discord}
                  h="40px"
                ></Image>
              </chakra.a>
            </Box>

            <Box display="inline-flex" w="100px" mt="2">
              <chakra.a
                color="white"
                w="full"
                p="2"
                href="https://nftkey.app/collections/onecan/"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                marginx="auto"
                border="solid transparent"
                fontWeight="bold"
                rounded="md"
                _hover={{
                  opacity: 0.5,
                }}
              >
                <Image
                  style={{
                    filter:
                      "invert(0%) sepia(100%) saturate(3%) hue-rotate(171deg) brightness(94%) contrast(101%)",
                    WebkitFilter:
                      "invert(0%) sepia(100%) saturate(3%) hue-rotate(171deg) brightness(94%) contrast(101%)",
                  }}
                  src={marketplace}
                  h="40px"
                ></Image>
              </chakra.a>
            </Box>
            <Box display="inline-flex" w="100px" mt="2">
              <chakra.a
                color="white"
                w="full"
                p="2"
                href="https://twitter.com/OneCanNFT"
                display="inline-flex"
                alignItems="center"
                justifyContent="center"
                marginx="auto"
                border="solid transparent"
                fontWeight="bold"
                rounded="md"
                _hover={{
                  opacity: 0.5,
                }}
              >
                <Image
                  cursor="url('/assets/cursor-hover.png'), auto;"
                  mx="2"
                  style={{
                    filter:
                      "invert(0%) sepia(100%) saturate(3%) hue-rotate(171deg) brightness(94%) contrast(101%)",
                  }}
                  src={twitter}
                  h="40px"
                ></Image>
              </chakra.a>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex alignItems="center">
        <Box position="relative">
          {!loading && (
            <Image
              position="absolute"
              bottom="0"
              right={{ md: -50, base: 0 }}
              maxH={{ md: 500, base: 300 }}
              transform="scaleX(-1)"
              src={harmony}
            />
          )}
          <Skeleton
            isLoaded={!loading}
            minWidth={{ base: "200px", md: "600px" }}
            minHeight={{ base: "200px", md: "400px" }}
          >
            <Image
              onLoad={() => setLoading(false)}
              border="10px solid white"
              mar
              src={gif}
            />
          </Skeleton>
        </Box>
      </Flex>
    </SimpleGrid>
  );
};
