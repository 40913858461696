import { Box } from "@chakra-ui/layout";
import { Link } from "react-router-dom";

type AccountLinkProps = {
  address: string;
};
export const AccountLink = (props: AccountLinkProps) => {
  return (
    <Link to={`/account/${props.address}`}>
      <Box
        fontFamily="mono"
        fontWeight="semibold"
        color="teal.500"
        fontSize="xl"
      >
        {props.address}
      </Box>
    </Link>
  );
};
