import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/popover";
import { Button } from "@chakra-ui/react";

export const ImagesNotLoadingInfo = () => {
  return (
    <Popover>
      <PopoverTrigger>
        <Button>Certain images not loading?</Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Images not loading</PopoverHeader>
        <PopoverBody textAlign="left">
          Most likely this is IPFS behaving shoddy. No worries! Your NFTs are
          fine. They're living it up, actually. You can click NFTs to go to
          their page individually and they're sure to show up.{" "}
          <b> Try refreshing the page </b>. Refreshing the page a few times may
          restablish IPFS connections as well.
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
