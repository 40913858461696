import { Box, Button, Link } from "@chakra-ui/react";
import { Contract } from "@ethersproject/contracts";
import { useState } from "react";
import { useEthers } from "../hooks";

type AdminPageProps = {
  contract?: Contract;
};
export const AdminPage = (props: AdminPageProps) => {
  const { provider } = useEthers();
  const [loading, setLoading] = useState(false);
  const [txHash, setTxHash] = useState("");

  const handleClick = async () => {
    if (provider && props.contract) {
      setLoading(true);
      const signer = provider.getSigner();
      const contractWithSigner = props.contract.connect(signer);

      try {
        let retval = await contractWithSigner.withdraw();
        await retval.wait(1);
        setTxHash(retval.hash);
        setLoading(false);
      } catch (err: any) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  return (
    <Box h="500px" display="flex" justifyContent="center" alignItems="center">
      <Button isLoading={loading} p="50px" fontSize="5xl" onClick={handleClick}>
        {" "}
        Withdraw babyyyy{" "}
      </Button>{" "}
      {txHash !== "" && (
        <Link
          m="5"
          p="5"
          border="1px"
          href={"https://explorer.harmony.one/tx/" + txHash}
        >
          {txHash}
        </Link>
      )}
    </Box>
  );
};
