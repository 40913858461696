import { AltHero, ThreeToucan, GifRow, Roadmap } from "../components";

export const LandingPage = () => {
  return (
    <>
      <AltHero />
      <GifRow />
      <Roadmap />
      <ThreeToucan />
    </>
  );
};
