import { Box, Heading } from "@chakra-ui/layout";
import { Contract } from "ethers";
import { useEffect, useState } from "react";
import { useEthers } from "../hooks";
import { all_metadata } from "../constants";

import {
  Button,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
} from "@chakra-ui/react";
import { OneCanCard } from "../components";

type SearchPageProps = {
  contract?: Contract;
};

export const SearchPage = (props: SearchPageProps) => {
  const allMetadata: any = all_metadata;
  const { account, provider } = useEthers();
  const [_supply, setSupply] = useState(1);
  const [results, setResults] = useState<Number[]>([]);
  const [numResults, setNumResults] = useState(50);

  const [loading, setLoading] = useState(false);
  const [valuePerches, setValuePerches] = useState("Any");
  const [valueToucans, setValueToucans] = useState("Any");
  const [valueEyeWear, setValueEyeWear] = useState("Any");
  const [valueBeaks, setValueBeaks] = useState("Any");
  const [valueHats, setValueHats] = useState("Any");
  const [valueMouthItems, setValueMouthItems] = useState("Any");
  const [valueWings, setValueWings] = useState("Any");
  const [valueFruits, setValueFruits] = useState("Any");

  useEffect(() => {
    const getCollection = async () => {
      // STEP 1: Get the current owner's balance
      if (props.contract) {
        let supply = await props.contract.totalSupply();
        // STEP 2: Loop through and grab all the NFTs this user owns
        setSupply(supply);
      }
    };

    if (account && provider && props.contract) {
      getCollection();
    }
  }, [account, props.contract, provider]);

  const handleClick = async () => {
    setResults([]);
    setLoading(true);

    const reduced = allMetadata.filter((option: any) => {
      if (
        valuePerches !== "Any" &&
        option.attributes[0].value !== valuePerches
      ) {
        return false;
      }
      if (
        valueToucans !== "Any" &&
        option.attributes[1].value !== valueToucans
      ) {
        return false;
      }
      if (valueBeaks !== "Any" && option.attributes[2].value !== valueBeaks) {
        return false;
      }
      if (valueHats !== "Any" && option.attributes[3].value !== valueHats) {
        return false;
      }
      if (
        valueMouthItems !== "Any" &&
        option.attributes[4].value !== valueMouthItems
      ) {
        return false;
      }
      if (valueWings !== "Any" && option.attributes[5].value !== valueWings) {
        return false;
      }
      if (
        valueEyeWear !== "Any" &&
        option.attributes[6].value !== valueEyeWear
      ) {
        return false;
      }
      if (valueFruits !== "Any" && option.attributes[7].value !== valueFruits) {
        return false;
      }
      return true;
    });

    let newResults = [];
    for (let i = 0; i < reduced.length; i++) {
      if (newResults.length >= numResults) {
        break;
      }
      const id = reduced.at(-i).external_url.split("/").at(-1);

      if (props.contract) {
        try {
          let number = (await props.contract.getMintNumber(id)).toNumber();
          if (number !== 0) {
            newResults.push(id);
          }
        } catch (err) {
          // pass
        }
      }
    }
    setResults(newResults);
    setLoading(false);
  };

  return (
    <Box>
      {account ? (
        <>
          <Box mt="7" textAlign="center" fontSize="2xl" fontWeight="bold">
            ✨ One🔎Search ✨
          </Box>
          <Box mt="7" textAlign="center" fontSize="lg" fontWeight="bold">
            {account && (
              <Box fontFamily="mono">
                <Box>
                  Beep boop. Searches from the {_supply.toString()} / 10,000
                  minted.
                </Box>
                <Box>
                  Returns {numResults} semirandom results (if available).
                </Box>
              </Box>
            )}
          </Box>
          <Box
            display={{ base: "block", md: "grid" }}
            gridTemplateColumns="repeat(2, 1fr)"
            p="5"
            border="1px"
            mt="4"
            flexWrap="wrap"
            maxW="600px"
            mx="auto"
            alignItems="center"
            justifyContent="center"
          >
            <Box m="3">
              <Text>Perches: </Text>
              <Select
                onChange={(event) => setValuePerches(event.target.value)}
                value={valuePerches}
              >
                <option value="Any">Any</option>
                <option value="Alien">Alien</option>
                <option value="Cloud">Cloud</option>
                <option value="Rainbow">Rainbow</option>
                <option value="Regular">Regular</option>
                <option value="Scary">Scary</option>
              </Select>
            </Box>
            <Box m="3">
              <Text>Toucans: </Text>
              <Select
                onChange={(event) => setValueToucans(event.target.value)}
                value={valueToucans}
              >
                <option value="Any">Any</option>
                <option value="Skeleton">Skeleton</option>
                <option value="Regular">Regular</option>
                <option value="Zombie">Zombie</option>
                <option value="Alien">Alien</option>
                <option value="HarmonyTWO Can">HarmonyTWO Can</option>
                <option value="B&W">B&W</option>
              </Select>
            </Box>
            <Box m="3">
              <Text>Eye Wear: </Text>
              <Select
                onChange={(event) => setValueEyeWear(event.target.value)}
                value={valueEyeWear}
              >
                <option value="Any">Any</option>
                <option value="None">None</option>
                <option value="Kawaii Eyes">Kawaii Eyes</option>
                <option value="Heart eyes">Heart eyes</option>
                <option value="Pooked Eyes">Pooked Eyes</option>
                <option value="Laser Eyes">Laser Eyes</option>
                <option value="Face Tat">Face Tat</option>
                <option value="Tears">Tears</option>
                <option value="Sunglasses">Sunglasses</option>
                <option value="Monocle">Monocle</option>
                <option value="Ye Shades">Ye Shades</option>
              </Select>
            </Box>
            <Box m="3">
              <Text>Beaks: </Text>
              <Select
                onChange={(event) => setValueBeaks(event.target.value)}
                value={valueBeaks}
              >
                <option value="Any">Any</option>
                <option value="None">None</option>
                <option value="Gold Beak">Gold Beak</option>
                <option value="Diamond Beak">Diamond Beak</option>
              </Select>
            </Box>
            <Box m="3">
              <Text>Hats: </Text>
              <Select
                onChange={(event) => setValueHats(event.target.value)}
                value={valueHats}
              >
                <option value="Any">Any</option>
                <option value="None">None</option>
                <option value="Safari Hat">Safari Hat</option>
                <option value="Vietnam Hat">Vietnam Hat</option>
                <option value="Beanie">Beanie</option>
                <option value="CR Flag">CR Flag</option>
                <option value="Fez">Fez</option>
                <option value="British Hat">British Hat</option>
                <option value="Mini Toucan">Mini Toucan</option>
                <option value="Viking Helmet">Viking Helmet</option>
                <option value="Mcdonalds hat">Mcdonalds hat</option>
                <option value="Fedora">Fedora</option>
                <option value="Crown">Crown</option>
              </Select>
            </Box>
            <Box m="3">
              <Text>Mouth Items: </Text>
              <Select
                onChange={(event) => setValueMouthItems(event.target.value)}
                value={valueMouthItems}
              >
                <option value="Any">Any</option>
                <option value="None">None</option>
                <option value="Cigarette">Cigarette</option>
                <option value="Vape">Vape</option>
                <option value="Joint">Joint</option>
                <option value="Pipe">Pipe</option>
              </Select>
            </Box>
            <Box m="3">
              <Text>Wings: </Text>
              <Select
                onChange={(event) => setValueWings(event.target.value)}
                value={valueWings}
              >
                <option value="Any">Any</option>
                <option value="None">None</option>
                <option value="Anime">Anime</option>
                <option value="Dragon">Dragon</option>
                <option value="Gold">Gold</option>
              </Select>
            </Box>
            <Box m="3">
              <Text>Fruits: </Text>
              <Select
                onChange={(event) => setValueFruits(event.target.value)}
                value={valueFruits}
              >
                <option value="Any">Any</option>
                <option value="Avocado">Avocado</option>
                <option value="Banana">Banana</option>
                <option value="Coconut">Coconut</option>
                <option value="Orange">Orange</option>
                <option value="Pinneaple">Pinneaple</option>
                <option value="Pipa">Pipa</option>
                <option value="Watermelon">Watermelon</option>
                <option value="None">None</option>
              </Select>
            </Box>
          </Box>
          <Box m="auto" maxW="300px" mt="2" border="1px" textAlign="center">
            Number of results wanted:
            <NumberInput
              size="lg"
              maxW={32}
              defaultValue={50}
              min={10}
              my="2"
              mx="auto"
              onChange={(event) => setNumResults(parseInt(event))}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Box m="auto" maxW="200px">
            <Button
              colorScheme="orange"
              w="100%"
              my="3"
              _hover={{
                transform: "scale(1.6)",
              }}
              onClick={handleClick}
              isLoading={loading}
            >
              Search 🔎
            </Button>
            {results.length > 0 && (
              <Box fontFamily="mono">Result size : {results.length}</Box>
            )}
          </Box>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {results.map((boxId) => {
              return <OneCanCard key={boxId.valueOf()} id={boxId.valueOf()} />;
            })}
          </Box>
        </>
      ) : (
        <Heading m="5">Please connect an account</Heading>
      )}
    </Box>
  );
};
