import { Box, Text } from "@chakra-ui/layout";
import { Heading } from "@chakra-ui/react";
import { distributionStats } from "../constants";

type TraitInfoProps = {
  data: any;
};

export const TraitInfo = (props: TraitInfoProps) => {
  const distro: any = distributionStats;
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="space-between" w="100%">
      {props.data.attributes.map((item: any) => {
        return (
          <Box
            m={{ base: "1", md: "2" }}
            background="blue.100"
            color="blue.900"
            boxShadow="md"
            p="3"
            rounded="lg"
            minW={{ base: "150px", md: "150px" }}
            _hover={{
              filter: "opacity(0.8)",
            }}
            textAlign="center"
            key={item.trait_type}
          >
            {" "}
            <Text size="sm" key={item.id}>
              {" "}
              {item.trait_type}{" "}
            </Text>{" "}
            <Heading size="md" key={item.id}>
              {" "}
              {item.value}{" "}
            </Heading>
            <Text fontSize="xs" key={item.id}>
              {" "}
              {distro[item.trait_type][item.value]} % have this trait
            </Text>{" "}
          </Box>
        );
      })}
    </Box>
  );
};
