import { Box, Divider, Heading } from "@chakra-ui/layout";
import { Container, Image, Text } from "@chakra-ui/react";

export const AttributesPage = () => {
  const stats: any = {
    Perches: {
      Rainbow: 2088,
      Cloud: 2083,
      Regular: 1968,
      Alien: 1951,
      Scary: 1910,
    },
    Toucans: {
      Regular: 6466,
      Skeleton: 1092,
      "B&W": 1104,
      Zombie: 817,
      Alien: 512,
      "HarmonyTWO Can": 9,
    },
    "Eye Wear": {
      Sunglasses: 941,
      "Pooked Eyes": 927,
      "Face Tat": 910,
      "Ye Shades": 909,
      "Kawaii Eyes": 837,
      Tears: 824,
      "Heart eyes": 684,
      "Laser Eyes": 665,
      Monocle: 382,
    },
    Beaks: { "Gold Beak": 172, "Diamond Beak": 45 },
    Hats: {
      "Safari Hat": 961,
      Beanie: 946,
      Fez: 931,
      "CR Flag": 731,
      Fedora: 728,
      "British Hat": 664,
      "Vietnam Hat": 631,
      "Mcdonalds hat": 627,
      "Viking Helmet": 614,
      "Mini Toucan": 483,
      Crown: 226,
    },
    "Mouth Items": {
      Vape: 2192,
      Cigarette: 1826,
      Joint: 855,
      Pipe: 690,
    },
    Wings: { Anime: 1712, Dragon: 1526, Gold: 807 },
    Fruits: {
      Pipa: 1300,
      Banana: 1262,
      Avocado: 1256,
      Orange: 1247,
      Pinneaple: 1245,
      Watermelon: 1241,
      Coconut: 1228,
    },
  };
  return (
    <Container mt="4" maxW="container.xl">
      {Object.keys(stats).map((key) => {
        return (
          <Box>
            <Heading size="lg"> {key} </Heading>
            <Box display="flex" flexWrap="wrap">
              {Object.keys(stats[key] as any).map((key2) => {
                let url = `/vanillas/${encodeURI(key)}/${encodeURI(key2)}.png`;
                return (
                  <Box
                    m="1"
                    border="1px"
                    p="2"
                    borderRadius="xl"
                    textAlign="center"
                  >
                    <Text fontWeight="bold">{key2}</Text>
                    <Image
                      key={url}
                      src={url}
                      w="140px"
                      mx="auto"
                      borderRadius="10%"
                      my="1"
                    />
                    <Text> {stats[key][key2]} / 10,000</Text>
                  </Box>
                );
              })}
            </Box>
            <Divider colorScheme="facebook" my="3" />
          </Box>
        );
      })}
    </Container>
  );
};
