import React, { Suspense } from "react";
import { Canvas, useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { TextureLoader } from "three/src/loaders/TextureLoader";
import { Box } from "@chakra-ui/react";

function Scene() {
  // Starting function with left curly bracket
  const [colorMap] = useLoader(TextureLoader, ["/img_2.png"]); // ?????
  return (
    <>
      <ambientLight intensity={0.2} />
      <pointLight />
      <mesh>
        <cylinderGeometry args={[5, 5, 0.93, 100]} attach="geometry" />
        <meshBasicMaterial map={colorMap} />
      </mesh>
    </>
  );
}

export const ThreeToucan = () => {
  return (
    <Box h="60vh" backgroundColor="sand">
      {" "}
      {/** Box with h height */}
      <Canvas camera={{ position: [4, 4, 4] }}>
        <Suspense fallback={null}>
          <Scene />
          <OrbitControls autoRotate enableZoom={false} />
        </Suspense>
      </Canvas>
    </Box>
  );
};
