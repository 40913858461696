import { Box, Heading, Text } from "@chakra-ui/layout";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import {
  ListItem,
  UnorderedList,
  Link,
  Image,
  useColorModeValue,
  Flex,
} from "@chakra-ui/react";
import RubberBand from "react-reveal/RubberBand";

import can1 from "./images/img_1.png";
import can2 from "./images/img_2.png";
import can3 from "./images/img_3.png";

export const Roadmap = () => {
  return (
    <Box
      w="100%"
      backgroundColor={useColorModeValue("sand", "extracandarkblue")}
      pb="5"
      pt="5"
    >
      <Flex justifyContent="center" flexDir="column" alignItems="center">
        <RubberBand>
          <Heading
            mb="10"
            fontSize={{ base: "5xl", md: "6xl" }}
            fontWeight="bold"
            lineHeight="none"
            letterSpacing={{ base: "normal", md: "tight" }}
            pt="7"
            // color={useColorModeValue("candarkblue", "canlightblue")}
            color={useColorModeValue("candarkgreen", "sand")}
            textAlign="center"
            margin="auto"
          >
            Roadmap
          </Heading>
        </RubberBand>
        <Text
          mt="2"
          fontStyle="italic"
          color={useColorModeValue("candarkblue", "white")}
        >
          Check it out in more detail on our{" "}
          <Link
            textDecoration="underline"
            href="https://onecan.medium.com/the-public-roadmap-is-here-enter-now-or-suffer-the-cansequences-9c0a238b45ff"
          >
            blog
          </Link>
        </Text>
      </Flex>
      <Box>
        <VerticalTimeline layout={"1-column-left"}>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#44a5d0", color: "#fff" }}
            contentArrowStyle={{
              borderRight: "7px solid  #44a5d0",
            }}
            date="2021"
            icon={<Image borderRadius="50%" alt="onecan" src={can1} />}
            iconStyle={{ background: "#44a5d0", color: "#fff" }}
          >
            <h1 className="vertical-timeline-element-title">Q4</h1>
            <Text>
              <UnorderedList
                textAlign="left"
                letterSpacing=".01rem"
                listStyleType="none"
                margin="0"
                padding="0"
              >
                <ListItem>
                  {" "}
                  ✔️ Build the community (Discord, Twitter, Talk Harmony, etc)
                </ListItem>
                <ListItem>
                  {" "}
                  ✔️ Attend DayONE Harmony One conference in San Francisco
                </ListItem>
                <ListItem> ✔️ Meet core Harmony team </ListItem>
                <ListItem>
                  {" "}
                  ✔️ Begin promoting and growing the community
                </ListItem>
                <ListItem> ✔️ Launch OneCans NFT </ListItem>
                <ListItem>
                  {" "}
                  ✔️ Launch website, deploy custom minting contract
                </ListItem>
                <ListItem>
                  {" "}
                  ✔️ List on{" "}
                  <Link href="https://nftkey.app/collections/onecan/">
                    NFTKEY
                  </Link>{" "}
                  secondary marketplace
                </ListItem>
              </UnorderedList>
            </Text>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#44a5d0", color: "#fff" }}
            contentArrowStyle={{
              borderRight: "7px solid  #44a5d0",
            }}
            date="2022"
            icon={<Image borderRadius="50%" alt="onecan" src={can2} />}
          >
            <h1 className="vertical-timeline-element-title">Q1</h1>
            <p>
              <UnorderedList
                textAlign="left"
                letterSpacing=".01rem"
                listStyleType="none"
                margin="0"
                padding="0"
              >
                <ListItem>
                  {" "}
                  ✔️ Onboarded a team of artists, making custom artwork for the
                  Peninsula of VolCan{" "}
                </ListItem>
                <ListItem>
                  {" "}
                  ✔️ Total supply of 10,000 OneCan NFTs minted{" "}
                </ListItem>
                <ListItem>
                  {" "}
                  ✔️ Developed the infrastructure for a multiplayer socially
                  interactive metaverse game{" "}
                </ListItem>
                <ListItem>
                  {" "}
                  ✔️ Launched closed beta version of Peninsula of VolCan
                </ListItem>
              </UnorderedList>
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#44a5d0", color: "#fff" }}
            contentArrowStyle={{
              borderRight: "7px solid  #44a5d0",
            }}
            date="2022"
            icon={<Image borderRadius="50%" alt="onecan" src={can3} />}
          >
            <h1 className="vertical-timeline-element-title">Q2</h1>
            <Text>
              <UnorderedList textAlign="left" letterSpacing=".01rem">
                <ListItem>
                  Creation of custom artwork for maps and characters{" "}
                </ListItem>
                <ListItem>World expands over time </ListItem>
                <ListItem>
                  Ever-expanding lore surrounding the Peninsula of VolCan and
                  its inhabitants{" "}
                </ListItem>
                <ListItem>
                  Use your OneCan as playable character in-game sprite{" "}
                </ListItem>
                <ListItem>OneCan Island Activities </ListItem>
                <ListItem>
                  Single player games (FlappyCans), party/head-to-head games,
                  and real-time community events (Tag, CTF){" "}
                </ListItem>
                <ListItem>Scheduled events and competitions </ListItem>
                <ListItem>
                  Grant Proposal launch on Talk Harmony to raise capital for
                  further game development{" "}
                </ListItem>
                <ListItem>Governance </ListItem>
                <ListItem>
                  OneCan NFT’s will be used to vote in community proposals on
                  <Link src="https://snapshot.org">snapshot.org</Link>
                </ListItem>
                <ListItem>Launch of Land Plots </ListItem>
              </UnorderedList>
            </Text>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#44a5d0", color: "#fff" }}
            contentArrowStyle={{
              borderRight: "7px solid  #44a5d0",
            }}
            date="2022"
            icon={<Image borderRadius="50%" alt="onecan" src={can1} />}
          >
            <h1 className="vertical-timeline-element-title">Q3</h1>
            <Text>
              <UnorderedList textAlign="left" letterSpacing=".01rem">
                <ListItem>Launch of useable in-game NFT items </ListItem>
                <ListItem>
                  Incorporation of Seasons mechanic, where the world changes
                  with each season as the story progresses{" "}
                </ListItem>
                <ListItem>
                  Expansion of the Peninsula of VolCan and its activities{" "}
                </ListItem>
                <ListItem>New maps, characters, mini-games, etc. </ListItem>
                <ListItem>Creation of in-game economy </ListItem>
                <ListItem>Evolution of cross-project metaverse </ListItem>
              </UnorderedList>
            </Text>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            contentStyle={{ background: "#44a5d0", color: "#fff" }}
            contentArrowStyle={{
              borderRight: "7px solid  #44a5d0",
            }}
            date="2022"
            icon={<Image borderRadius="50%" alt="onecan" src={can2} />}
          >
            <h1 className="vertical-timeline-element-title">
              Q4 (subject to change)
            </h1>
            <Text>
              <UnorderedList textAlign="left" letterSpacing=".01rem">
                <ListItem>Breeding</ListItem>
                <ListItem>BabyCans, with use-cases in-game</ListItem>
                <ListItem>SanctuaryDAO</ListItem>
                <ListItem>
                  Primary purpose of the DAO will be to fund a Toucan sanctuary
                  in Costa Rica
                </ListItem>
                <ListItem>Cross-chain expansion</ListItem>
              </UnorderedList>
            </Text>
          </VerticalTimelineElement>
        </VerticalTimeline>
      </Box>
    </Box>
  );
};
