import { Box } from "@chakra-ui/layout";
import {
  Stat,
  StatArrow,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";

type StatsAccountProps = {
  owned: number;
};
export const StatsAccount = (props: StatsAccountProps) => {
  return (
    <Box maxW="400px" w="100%" mx="auto" mt="4" border="2px" p="4" rounded="xl">
      <StatGroup>
        <Stat>
          <StatLabel>Owned</StatLabel>
          <StatNumber>{props.owned}</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            13.37%
          </StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Rank</StatLabel>
          <StatNumber>
            {props.owned === 0
              ? "lurker"
              : props.owned === 1
              ? "Beginning OneCaner"
              : props.owned < 5
              ? "OneCan Man"
              : props.owned < 8
              ? "Can-Can can you do the OneCan"
              : props.owned < 11
              ? "OneCan senpai"
              : props.owned < 20
              ? "OneCan Superman"
              : props.owned < 30
              ? "OneCannot Stop Me"
              : props.owned < 50
              ? "Onetoshi Canamoto"
              : props.owned < 100
              ? "CRYPTO CHAD"
              : "0x90d"}
          </StatNumber>
          <StatHelpText>
            {props.owned === 0
              ? "Cmon you know u wanna"
              : props.owned === 1
              ? "A journey of a million One begins with a single Can"
              : props.owned < 5
              ? "OneCan one cup?"
              : props.owned < 8
              ? "*kicks legs*"
              : props.owned < 11
              ? "i-i-im not blushing!"
              : props.owned < 20
              ? "The hero we needed"
              : props.owned < 30
              ? "CAUSE IM HAVING A GOOD TIME"
              : props.owned < 50
              ? "The most mysterious OneCanner indeed"
              : props.owned < 100
              ? "Crypto stacey hmu"
              : "I AM BECOME CAN DESTORYER OF ONE"}
          </StatHelpText>
        </Stat>
      </StatGroup>{" "}
    </Box>
  );
};
