import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  fonts: {
    heading: "Fredoka One",
    body: "Fredoka One",
  },
  styles: {
    global: {
      html: {
        cursor: "url('/assets/cursor.png'), auto;",
      },
      body: {
        bg: "ebony-clay",
        color: "white",
      },
      a: {
        _hover: {
          cursor: "url('/assets/cursor-hover.png'), auto;",
        },
        _active: {
          cursor: "url('/assets/cursor-active.png'), auto;",
          transform: "scale(0.98)",
        },
      },
    },
  },

  colors: {
    maroon: "#be4a2f",
    "red-damask": "#d77643",
    chamois: "#ead4aa",
    "lighter-brown": "#e4a672",
    "cornflower-blue": "#b86f50",
    lotus: "#733e39",
    "woody-brown": "#3e2731",
    "mexican-red": "#a22633",
    cinnabar: "#e43b44",
    ecstasy: "#f77622",
    "yellow-orange": "#feae34",
    "candy-corn": "#fee761",
    mantis: "#63c74d",
    goblin: "#3e8948",
    plantation: "#265c42",
    "gable-green": "#193c3e",
    "chathams-blue": "#124e89",
    cerulean: "#0099db",
    "bright-turquoise": "#2ce8f5",
    white: "#ffffff",
    "blue-haze": "#c0cbdc",
    "bali-hai": "#8b9bb4",
    "waikawa-gray": "#5a6988",
    fiord: "#3a4466",
    "ebony-clay": "#262b44",
    mirage: "#181425",
    "torch-red": "#ff0044",
    voodoo: "#68386c",
    tapestry: "#b55088",
    froly: "#f6757a",
    "gold-sand": "#e8b796",
    "antique-brass": "#c28569",
    canlightblue: "#44a5d0",
    candarkblue: "#0b67a8",
    candarkgreen: "#6ba31c",
    canlightgreen: "#ccd91a",
    candarkbrown: "#c89e6c",
    canlightbrown: "#f3e4cd",
    extracandarkblue: "#1C2B2D",
    extracangrey: "#99A8B2",
    sand: "#E6D5B8",
  },
  components: {
    Button: {
      baseStyle: {
        cursor: "url('/assets/cursor.png'), auto;",
      },
      defaultProps: {
        variant: "default",
      },
      variants: {
        default: {
          bg: "sand",
          color: "candarkblue",
          _hover: {
            bg: "fiord",
          },
        },
        blue: {
          bg: "cerulean",
          _hover: {
            bg: "chathams-blue",
          },
        },
        red: {
          bg: "cinnabar",
          _hover: {
            bg: "mexican-red",
          },
        },
        green: {
          bg: "goblin",
          _hover: {
            bg: "plantation",
          },
        },
      },
    },
  },
});
