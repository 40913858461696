import gif_1 from "./images/gif_1.gif";
import gif_2 from "./images/gif_2.gif";
import gif_3 from "./images/gif_3.gif";

import { HStack, useColorModeValue } from "@chakra-ui/react";
import { TiltImgCard } from "./TiltImgCard";

export const GifRow = () => {
  return (
    <HStack
      backgroundColor={useColorModeValue("sand", "extracandarkblue")}
      justify="center"
      spacing="auto"
      display={{ base: "none", md: "flex" }}
    >
      <TiltImgCard imgsrc={gif_1} />
      <TiltImgCard imgsrc={gif_2} />
      <TiltImgCard imgsrc={gif_3} />
      <TiltImgCard imgsrc={gif_1} />
      <TiltImgCard imgsrc={gif_2} />
    </HStack>
  );
};
