import OneCanNFTContract from "./contracts/OneCanNFT.json";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import { useEthersContext, EthersContext } from "./hooks";
import {
  AccountPage,
  AdminPage,
  AttributesPage,
  LandingPage,
  OneCanInfoPage,
  RecentlyMintedPage,
  SearchPage,
} from "./pages";
import { Navbar } from "./components";
import { Contract, ethers } from "ethers";
import { useEffect, useState } from "react";
import { Fonts } from "./components/Fonts";
import { ScrollPage } from "./pages/ScrollPage";

const { REACT_APP_CONTRACT_ENVIRONMENT } = process.env;

function App() {
  const { connectToWallet, provider, account } = useEthersContext();
  const [contract, setContract] = useState<Contract>();

  const contractProdAddress = "0xf80790b2afc34390d5af12a3a332d12ff1886b42";
  const contractTestAddress = "0x10236CdDA143f6470e0316236E532895476d8cA8";

  let contractAddress =
    REACT_APP_CONTRACT_ENVIRONMENT === "test"
      ? contractTestAddress
      : contractProdAddress;

  useEffect(() => {
    const setup = async () => {
      const tokenContract = new ethers.Contract(
        contractAddress,
        OneCanNFTContract.abi,
        provider
      );
      await setContract(tokenContract);
    };
    if (account && provider) {
      setup();
    }
  }, [account, provider, contractAddress]);

  return (
    <EthersContext.Provider
      value={{
        provider,
        account,
        connectToWallet,
      }}
    >
      <Fonts />
      <Box>
        <Router>
          <Navbar />
          <Switch>
            <Route path="/onecan/:oneCanId">
              <OneCanInfoPage contract={contract} />
            </Route>
            <Route path="/account/:accountAddress">
              <AccountPage contract={contract} />
            </Route>
            <Route path="/search">
              <SearchPage contract={contract} />
            </Route>
            <Route path="/account">
              <AccountPage contract={contract} />
            </Route>
            <Route path="/admin">
              <AdminPage contract={contract} />
            </Route>
            <Route path="/attributes">
              <AttributesPage />
            </Route>
            <Route path="/recents">
              <RecentlyMintedPage contract={contract} />
            </Route>
            <Route path="/scrolls/:accountAddress">
              <ScrollPage contract={contract} />
            </Route>
            <Route path="/scrolls/">
              <ScrollPage contract={contract} />
            </Route>
            <Route path="/">
              <LandingPage />
            </Route>
          </Switch>
        </Router>
      </Box>
    </EthersContext.Provider>
  );
}

export default App;
