import { Box, Button, chakra } from "@chakra-ui/react";

type DownloadImageButtonProps = {
  imageLink: string;
  filename: string;
  children?: React.ReactNode;
};

const _DownloadImageButton = (props: DownloadImageButtonProps) => {
  const fetchImage = async (imageURL: string) => {
    fetch(imageURL)
      .then((value) => {
        console.log(value);
        value
          .blob()
          .then((value) => {
            const newBlob = new Blob([value]);

            const element = document.createElement("a");
            document.body.appendChild(element);
            element.setAttribute("href", window.URL.createObjectURL(newBlob));
            element.setAttribute("download", props.filename);
            element.style.display = "";

            element.click();
            document.body.removeChild(element);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box w="100%" textAlign="center">
      <Button
        m={1}
        w="60%"
        colorScheme="facebook"
        onClick={() => fetchImage(props.imageLink)}
      >
        {props.children}
      </Button>
    </Box>
  );
};

export const DownloadImageButton = chakra(_DownloadImageButton);
