import WalletConnectProvider from "@walletconnect/web3-provider";

import exportedMetadata from "./flattenedMetadata.json";

export const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      rpc: {
        56: "https://bsc-dataseed.binance.org/",
      },
    },
  },
};

export const distributionStats = {
  Perches: {
    Scary: 19.1,
    Regular: 19.68,
    Alien: 19.51,
    Cloud: 20.83,
    Rainbow: 20.88,
  },
  Toucans: {
    Regular: 64.66,
    "B&W": 11.04,
    Skeleton: 10.92,
    Alien: 5.12,
    Zombie: 8.17,
    "HarmonyTWO Can": 0.09,
  },
  "Eye Wear": {
    None: 29.21,
    Sunglasses: 9.41,
    "Pooked Eyes": 9.27,
    "Face Tat": 9.1,
    Tears: 8.24,
    "Ye Shades": 9.09,
    "Laser Eyes": 6.65,
    "Kawaii Eyes": 8.37,
    "Heart eyes": 6.84,
    Monocle: 3.82,
  },
  Beaks: { None: 97.83, "Gold Beak": 1.72, "Diamond Beak": 0.45 },
  Hats: {
    None: 24.58,
    "Mini Toucan": 4.83,
    Beanie: 9.46,
    "CR Flag": 7.31,
    Fez: 9.31,
    "British Hat": 6.64,
    Fedora: 7.28,
    Crown: 2.26,
    "Safari Hat": 9.61,
    "Mcdonalds hat": 6.27,
    "Viking Helmet": 6.14,
    "Vietnam Hat": 6.31,
  },
  "Mouth Items": {
    Cigarette: 18.26,
    Pipe: 6.9,
    None: 44.37,
    Joint: 8.55,
    Vape: 21.92,
  },
  Wings: { Anime: 17.12, None: 59.55, Dragon: 15.26, Gold: 8.07 },
  Fruits: {
    Orange: 12.47,
    Banana: 12.62,
    Pinneaple: 12.45,
    None: 12.21,
    Pipa: 13.0,
    Avocado: 12.56,
    Coconut: 12.28,
    Watermelon: 12.41,
  },
};

export const all_metadata = exportedMetadata;
