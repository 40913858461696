import {
  Button,
  useToast,
  chakra,
  Box,
  Link,
  Heading,
  Progress,
  Text,
} from "@chakra-ui/react";
import { useEthers } from "../hooks";
import { useEffect, useRef, useState } from "react";
import { Contract } from "ethers";
import { parseEther } from "@ethersproject/units";
import party from "party-js";

type MintButtonProps = {
  contract?: Contract;
  onMint?: Function;
};

const _MintButton = (props: MintButtonProps) => {
  const mintCost = parseEther("100.0");
  const ref = useRef<HTMLButtonElement>(null);
  const plusRef = useRef<HTMLButtonElement>(null);

  const [numMint, setNumMint] = useState(1);
  const [txHash, setTxHash] = useState(null);

  const toast = useToast();
  const { provider } = useEthers();
  const [loading, setLoading] = useState(false);

  const numMintModifier = 1;

  useEffect(() => {
    if (ref !== null) {
      ref?.current?.addEventListener("click", function (e) {
        party.confetti(this);
      });
    }
  }, []);

  useEffect(() => {
    if (plusRef !== null) {
      plusRef?.current?.addEventListener("click", function (e) {
        party.sparkles(this);
      });
    }
  }, []);

  const handleClick = async () => {
    setLoading(true);
    if (provider && props.contract) {
      const signer = provider.getSigner();
      const contractWithSigner = props.contract.connect(signer);
      const amount = mintCost.mul(numMint);
      try {
        let retval = await contractWithSigner?.mintNFT(
          numMint * numMintModifier,
          {
            value: amount,
          }
        );
        //
        // TX PENDING HERE
        //
        // DO SOMETHING TO NOT BE BORING
        //
        await retval.wait(1);
        setTxHash(retval.hash);
        if (props.onMint) {
          props.onMint(numMint);
        }
      } catch (err: any) {
        if (err?.code === 4001) {
          toast({
            title: "Transaction rejected UwU",
            description: "Senpai doesn't want to mint an NFT 😳",
            status: "error",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
        }
        if (
          err.code === "UNPREDICTABLE_GAS_LIMIT" ||
          err.code === "INSUFFICIENT_FUNDS"
        ) {
          toast({
            title: "Network error",
            description:
              "Make sure you're on the HarmonyONE network on metamask!",
            status: "error",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
        }
        console.log(err.code);
      }
    } else {
      toast({
        title: "Connection to network failed",
        description: "Check network (Harmony mainnet) 😳",
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  return (
    <>
      <Box
        w="100%"
        textAlign="center"
        mx="auto"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Button
          onClick={() => {
            if (numMint > 1) {
              setNumMint(numMint - 1);
            }
          }}
          shadow="lg"
          mx="2"
          variant="solid"
          colorScheme="facebook"
          _hover={{
            transform: "scale(1.1)",
          }}
        >
          -
        </Button>
        <Box w="fit-content" borderRadius="full">
          <Button
            fontSize="2xl"
            fontWeight="extrabold"
            shadow="dark-lg"
            onClick={handleClick}
            isLoading={loading}
            ref={ref}
            color="white"
            bgGradient="linear(170deg, navy, deepskyblue 80%)"
            p="35"
            m="4"
            _hover={{
              transform: "scale(1.2)",
              bgGradient: "linear(170deg, orange, red 80%)",
            }}
          >
            Mint {numMint * numMintModifier}!{" "}
          </Button>
          <Button
            onClick={() => {
              if (numMint < 100) {
                setNumMint(numMint + 1);
              } else {
                toast({
                  title: "Sorry chief",
                  description: "💯 is the max mint in a single tx😳",
                  status: "error",
                  position: "top-right",
                  duration: 9000,
                  isClosable: true,
                });
              }
            }}
            shadow="lg"
            mx="2"
            variant="solid"
            colorScheme="facebook"
            fontSize="2xl"
            fontWeight="extrabold"
            _hover={{
              opacity: 0.5,
            }}
            ref={plusRef}
          >
            +
          </Button>
        </Box>
      </Box>
      <Box maxW="300px" margin="auto">
        <Progress
          value={((numMint * numMintModifier) % 10) * 10}
          size="md"
          colorScheme="pink"
          border="1px"
        />
        <Text>
          Add {10 - ((numMint * numMintModifier) % 10)} more for{" "}
          {numMintModifier} free OneCans!{" "}
        </Text>
      </Box>

      {Math.floor(numMint / 10) >= 1 ? (
        <Box
          m="auto"
          w="100%"
          maxWidth={{ base: "350px", md: "500px" }}
          boxShadow="md"
          textAlign="center"
          p="5"
          rounded="2xl"
          color="white"
          backgroundColor="orange.400"
        >
          <Heading>
            {" "}
            🎁 Bonus: {Math.floor((numMint * numMintModifier) / 10)} free mint
            {Math.floor((numMint * numMintModifier) / 10) > 1 ? "s" : ""}!{" "}
          </Heading>
        </Box>
      ) : (
        ""
      )}
      {txHash && (
        <Box
          m="auto"
          w="100%"
          maxWidth="300px"
          boxShadow="md"
          p="5"
          rounded="3xl"
        >
          Yay 🎉 Minted 🎉 at transaction{" "}
          <Link
            color="teal.500"
            isExternal
            href={"https://explorer.harmony.one/tx/" + txHash}
          >
            {txHash}
          </Link>
        </Box>
      )}
      <Box h="300px"></Box>
    </>
  );
};

export const MintButton = chakra(_MintButton);
