import { Box } from "@chakra-ui/layout";
import { Contract } from "@ethersproject/contracts";
import { MintButton, OneCanCard, TiltImgCard } from "../components";
import { motion } from "framer-motion";
import { BoxProps, Container, Text, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useEthers } from "../hooks";

type MintPageProps = {
  contract?: Contract;
};
export const MotionBox = motion<BoxProps>(Box);

export const MintPage = (props: MintPageProps) => {
  const toast = useToast();

  const { account, provider } = useEthers();
  const [mintIsComplete, setMintIsComplete] = useState(false);
  const [latestUserOneCan, setLatestUserOneCan] = useState(-1);
  const [numberMinted, setNumberMinted] = useState(0);

  const numMintModifier = 1;

  const mintComplete = (numMint: number) => {
    setMintIsComplete(true);
    setNumberMinted(numMint);
  };

  useEffect(() => {
    if (provider) {
      provider.getNetwork().then((value) => {
        const chainId = value.chainId;
        if (chainId !== 1666600000) {
          toast({
            title: "Sorry boss, quick network concern ",
            description:
              "Make sure you're on the HarmonyONE mainnet network on metamask pwease 🥺. Refresh when you are 😘",
            status: "error",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    }
  }, [provider, toast]);

  useEffect(() => {
    const getLatestUserOneCan = async () => {
      if (props.contract) {
        const balance = await props.contract.balanceOf(account);
        if (balance > 0) {
          const ans = await props.contract.tokenOfOwnerByIndex(
            account,
            balance - 1
          );
          setLatestUserOneCan(ans);
        }
      }
    };
    if (mintIsComplete) {
      getLatestUserOneCan();
    }
  }, [mintIsComplete, account, props.contract]);

  return (
    <Box
      h="100vh"
      w="100%"
      bgImage={mintIsComplete ? "/matrix.gif" : ""}
      bgRepeat="repeat"
    >
      <Box w="100%" mt="5">
        {!mintIsComplete && (
          <Box mt="3" w="fit-content" margin="auto" textAlign="center">
            <Container>Price per mint: 100 ONE</Container>
            <TiltImgCard imgsrc="/toucan_black.png" />
          </Box>
        )}
        {mintIsComplete && (
          <Box
            display="flex"
            flexFlow="wrap"
            justifyContent="center"
            alignItems="center"
          >
            {latestUserOneCan > 0 ? <OneCanCard id={latestUserOneCan} /> : ""}
            {numberMinted > 0 && (
              <Box m="4" p="5" rounded="2xl" bg="blue.500" fontWeight="black">
                <Text> You minted a beautiful OneCan </Text>
                <Text fontSize="5xl">
                  {numberMinted === 1
                    ? "Check it out in your collection"
                    : `+ ${
                        numberMinted * numMintModifier - 1
                      } more and ${Math.floor(
                        (numberMinted * numMintModifier) / 10
                      )} bonus!`}
                </Text>
              </Box>
            )}
          </Box>
        )}
        <MintButton contract={props.contract} onMint={mintComplete} />
      </Box>
    </Box>
  );
};
