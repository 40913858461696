import { Button, useColorMode } from "@chakra-ui/react";

export function DarkModeToggleButton() {
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Button onClick={toggleColorMode} size="md" fontSize="2xl">
      {colorMode === "light" ? "🌚" : "🌞"}
    </Button>
  );
}
