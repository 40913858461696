import React, { useEffect, useState } from "react";
import { Box, Heading, Text } from "@chakra-ui/layout";
import { useParams } from "react-router";
import {
  Skeleton,
  Image,
  SimpleGrid,
  Spinner,
  Input,
  InputRightElement,
  Button,
  InputGroup,
  useToast,
} from "@chakra-ui/react";
import { AccountLink, DownloadImageButton, TraitInfo } from "../components";
import { Contract } from "@ethersproject/contracts";
import { useEthers } from "../hooks";

type OneCanInfoPageProps = {
  contract?: Contract;
};

export const OneCanInfoPage = (props: OneCanInfoPageProps) => {
  const ownerDoesNotExist = "OWNER DOES NOT EXIST";
  const { oneCanId }: any = useParams();
  const { account, provider } = useEthers();
  const toast = useToast();

  const [loadingCard, setLoadingCard] = useState(true);
  const [fetchedData, setFetchedData] = useState(null);
  const [imageLink, setImageLink] = useState("");
  const [owner, setOwner] = useState("");
  const [mintNumber, setMintNumber] = useState(0);
  const [toAccount, setToAccount] = useState("");

  useEffect(() => {
    const getMintNumber = async () => {
      if (props.contract) {
        try {
          setMintNumber(
            (await props.contract.getMintNumber(oneCanId)).toNumber()
          );
        } catch (err) {
          console.log(err);
        }
      }
    };

    if (props.contract) {
      getMintNumber();
    }
  }, [props.contract, oneCanId]);

  useEffect(() => {
    fetch(
      `https://gateway.pinata.cloud/ipfs/QmcdTGLJzKoLG7mWz3krLZZTd9LnNJKfu2ERLYqhtv5sPd/${oneCanId}`
    ).then((resp) => {
      if (resp.ok) {
        resp.json().then((content) => {
          setFetchedData(content);
          setImageLink(content.image);
        });
      } else {
        console.log("Could not fetch data");
      }
    });
  }, [oneCanId]);

  useEffect(() => {
    const getOwner = async () => {
      if (props.contract) {
        try {
          let _owner = await props.contract.ownerOf(oneCanId);
          setOwner(_owner);
        } catch (err) {
          setOwner(ownerDoesNotExist);
        }
      }
    };

    if (props.contract) {
      getOwner();
    }
  }, [props.contract, oneCanId]);

  const handleChange = (event: any) => {
    setToAccount(event.target.value);
  };

  const handleClick = async () => {
    if (props.contract && provider) {
      const signer = provider.getSigner();
      const contractWithSigner = props.contract.connect(signer);
      toast({
        title: "Transfer in progress...",
        description: "Quid Pro Quo",
        status: "info",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      await contractWithSigner.transferFrom(account, toAccount, oneCanId);
      toast({
        title: "Transfer success!",
        description: "You 🤝 That guy (Give it a minute to propogate 🤗)",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  if (owner && owner !== ownerDoesNotExist) {
    return (
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={0}
        _after={{
          bg: "brand.500",
          opacity: 0.25,
          pos: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: -1,
          content: '" "',
        }}
      >
        <Skeleton rounded="lg" isLoaded={!loadingCard}>
          <Image
            alt="toucan"
            rounded="md"
            src={imageLink}
            onLoad={() => {
              setLoadingCard(false);
            }}
            w="100%"
          />
          <DownloadImageButton
            imageLink={imageLink}
            filename="my_amazing_onecan.png"
          >
            Download Image
          </DownloadImageButton>
        </Skeleton>
        <Box mt="4" mr="4" ml="2" maxW="700px">
          <Box
            textAlign="center"
            mx="auto"
            display="flex"
            flexWrap="wrap"
            p="3"
            boxShadow="md"
            rounded="lg"
          >
            <Heading>Attributes:</Heading>
            {fetchedData && <TraitInfo data={fetchedData} />}
            <Box
              mx="auto"
              bg="teal.200"
              boxShadow="sm"
              p="3"
              rounded="lg"
              my="8"
              textAlign="center"
              color="blue.900"
              _hover={{
                filter: "opacity(0.8)",
              }}
            >
              <Text fontSize="lg">Mint: </Text>
              {mintNumber !== 0 && (
                <Box display="flex" justifyContent="center" alignItems="end">
                  {" "}
                  <Heading> # {mintNumber}</Heading> <Text> / 10,000 </Text>{" "}
                </Box>
              )}
            </Box>

            <Box
              mx="auto"
              bg="orange.200"
              boxShadow="sm"
              p="3"
              rounded="lg"
              my="8"
              textAlign="center"
              color="blue.900"
              _hover={{
                filter: "opacity(0.8)",
              }}
              minW="200px"
            >
              <Text fontSize="lg">I.D. Number: </Text>
              {mintNumber !== 0 && (
                <Box display="flex" justifyContent="center" alignItems="end">
                  {" "}
                  <Heading> {oneCanId} </Heading>{" "}
                </Box>
              )}
            </Box>
          </Box>

          <Box boxShadow="md" p="3" rounded="lg" my="8">
            <Heading fontWeight="bold">Owner:</Heading>
            <Text my="4">
              {owner && owner === account ? (
                <Box>
                  <AccountLink address={owner} />
                  (This is you! 😎)
                </Box>
              ) : (
                <AccountLink address={owner} />
              )}
            </Text>
            {owner && owner === account ? (
              <Box>
                <Heading>Transfer to:</Heading>
                <Text>
                  {" "}
                  👉 Careful here! Make sure you put in the correct address (in
                  Ether format 0x...){" "}
                </Text>
                <InputGroup my="4">
                  <Input
                    onChange={handleChange}
                    variant="filled"
                    placeholder="0xdEcaf1337..."
                  />
                  <InputRightElement w="100px">
                    <Button
                      onClick={handleClick}
                      size="md"
                      colorScheme="facebook"
                      _hover={{
                        transform: "scale(1.1)",
                        willChange: "transform",
                      }}
                    >
                      Transfer!
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      </SimpleGrid>
    );
  } else {
    return (
      <Box w="100%" textAlign="center" h="600px" my="auto">
        {owner === ownerDoesNotExist ? (
          <Heading mt="25">This OneCan does not exist (yet!)</Heading>
        ) : (
          <Spinner size="lg"></Spinner>
        )}
      </Box>
    );
  }
};
