import { Box, Image } from "@chakra-ui/react";
import { useEffect } from "react";
import { useRef } from "react";
import VanillaTilt from "vanilla-tilt";

type TiltImgCardProps = {
  imgsrc?: string;
};

export const TiltImgCard = (props: TiltImgCardProps) => {
  const ref: any = useRef<HTMLDivElement>(null);
  useEffect(() => {
    VanillaTilt.init(ref.current, {
      max: 20,
      speed: 400,
      glare: true,
      "max-glare": 0,
      scale: 0.95,
    });
  });

  return (
    <div ref={ref}>
      <Box m="4" p="1" maxW="sm" w="fit-content" rounded="2xl" boxShadow="2xl">
        <Image src={props.imgsrc} alt="toucan" rounded="2xl" />
      </Box>
    </div>
  );
};
