import { Box, Flex } from "@chakra-ui/layout";
import { Contract } from "ethers";
import { useEffect } from "react";
import { useEthers } from "../hooks";
import { TiltImgCard } from "../components";
import { useToast } from "@chakra-ui/toast";
import { chakra } from "@chakra-ui/react";

type ScrollPageProps = {
  contract?: Contract;
};

export const ScrollPage = (props: ScrollPageProps) => {
  const toast = useToast();
  // const { accountAddress }: any = useParams();
  const { provider } = useEthers();
  // const [ownedNFTs, setOwnedNFTs] = useState<Number[][]>([[]]);

  // useEffect(() => {
  //   if (accountAddress === "" && account) {
  //     let goTo = `/scrolls/${account}`;
  //   }
  // });
  // useEffect(() => {
  //   const getCollection = async () => {
  //     if (ethers.utils.isAddress(accountAddress)) {
  //       if (props.contract) {
  //         setOwnedNFTs([]);
  //         console.log(accountAddress);
  //         let balances = await props.contract.queryForOwnedScrolls(
  //           accountAddress
  //         );
  //         console.log(balances.CommonBalanceArr);
  //         console.log(balances.LegendaryBalanceArr);
  //         console.log(balances.RareBalanceArr);
  //         setOwnedNFTs(balances);
  //       }
  //     }
  //   };

  //   if (account && provider && props.contract) {
  //     getCollection();
  //   }
  // }, [account, props.contract, provider, accountAddress]);

  useEffect(() => {
    if (provider) {
      provider.getNetwork().then((value) => {
        const chainId = value.chainId;
        if (chainId !== 1666600000) {
          toast({
            title: "Sorry boss, quick network concern ",
            description:
              "Make sure you're on the HarmonyONE mainnet network on metamask pwease 🥺. Refresh when you are 😘",
            status: "error",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
        }
      });
    }
  }, [provider, toast]);

  return (
    <Box>
      <Box mt="7" textAlign="center" fontSize="2xl" fontWeight="bold">
        Holy bowl! Stroll down to the SCROLL down, we got ourselves a parchment
        pleaser
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="center">
        <Box mx="auto" w="100%" textAlign="center">
          <Box display="inline-flex" w={{ base: "100%", md: "300px" }} mt="2">
            <chakra.a
              color="white"
              w="full"
              p="2"
              href="https://docs.onecan.one/the-island/whitelist-scrolls"
              display="inline-flex"
              alignItems="center"
              justifyContent="center"
              marginx="auto"
              marginy="10px"
              border="solid transparent"
              fontWeight="bold"
              rounded="md"
              bg="canlightblue"
              fontSize="2xl"
              _hover={{
                opacity: 0.5,
              }}
            >
              Docs!
            </chakra.a>
          </Box>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box mx="auto" w="100%" textAlign="center">
              <Box
                display="inline-flex"
                w={{ base: "100%", md: "300px" }}
                mt="2"
              >
                <chakra.a
                  color="white"
                  w="full"
                  p="2"
                  href="https://nftkey.app/collections/onecanscrolls/"
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="center"
                  marginx="auto"
                  marginy="10px"
                  border="solid transparent"
                  fontWeight="bold"
                  rounded="md"
                  bg="canlightblue"
                  fontSize="2xl"
                  _hover={{
                    opacity: 0.5,
                  }}
                >
                  Marketplace!
                </chakra.a>
              </Box>
            </Box>
          </Box>
        </Box>
        <Flex justifyContent="center" flexWrap="wrap">
          <TiltImgCard imgsrc="/common_scroll.jpeg" />
          <TiltImgCard imgsrc="/rare_scroll.jpeg" />
          <TiltImgCard imgsrc="/legendary_scroll.jpeg" />
        </Flex>
      </Box>
    </Box>
  );
};
